.ant-card-body {
  .ant-form {
    margin: auto;
  }
}

.ant-table-tbody {
  .row-expired {
    color: #ccc;
  }
  .row-actived {
    color: green;
    font-weight: bold;
  }
}
