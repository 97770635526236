// html {
//   @media screen and (min-width: 360px) and (max-width: 374px) {
//     font-size: 703%;
//   }
//   @media screen and (min-width: 375px) and (max-width: 383px) {
//     font-size: 732.4%;
//   }
//   @media screen and (min-width: 384px) and (max-width: 399px) {
//     font-size: 750%;
//   }
//   @media screen and (min-width: 400px) and (max-width: 413px) {
//     font-size: 781.25%;
//   }
//   @media screen and (min-width: 414px) and (max-width: 431px) {
//     font-size: 808.6%;
//   }
//   @media screen and (min-width: 432px) and (max-width: 479px) {
//     font-size: 843.75%;
//   }
// }
.main {
  font-size: 10vw;
  &-app {
    background: #c7a981;
    padding: 10vw 10vw;
    margin: auto;
    height: 100vh;
    &-loading {
      .ant-spin-dot-item {
        background: #c09462;
      }
    }
    &-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      overflow: hidden;
      &-header {
        flex: 1.5;
        background: linear-gradient(to right, rgba(253, 235, 215, 1) 0%, rgba(224, 196, 155, 1) 100%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &-title {
          // font-size: 14px;
          color: #c09462;
          padding-top: 5px;
        }
        &-main {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // font-size: 14px;
            color: #c09462;
            &-check {
              height: 22px;
              width: 22px;
              background: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;
              margin-left: 10px;
              img {
                height: 15px;
                width: 15px;
              }
            }
          }
          &-right {
            background: #f5ebdd;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 3px 6px;
            span {
              // font-size: 14px;
              padding-right: 6px;
              color: #c09462;
            }
            img {
              width: 10px;
              height: 10px;
            }
          }
        }
        &-button {
          height: 6vw;
          line-height: 6vw;
          text-align: center;
          margin-bottom: -3vw;
          z-index: 2;
          background: linear-gradient(to right, #f4d1a0 0%, #e3bd89 100%);
          border-radius: 40px;
          padding-left: 3vw;
          padding-right: 3vw;
          color: #fff;
        }
      }
      &-content {
        flex: 7;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-qrcode {
          padding-top: 8vw;
          padding-left: 10vw;
          padding-right: 10vw;
          img {
            width: 100%;
          }
          div {
            margin-top: 3vw;
            font-size: 12px;
            text-align: center;
          }
        }
        &-note {
          font-size: 12px;
          padding-top: 10vw;
          padding-left: 5vw;
          padding-right: 5vw;
          &-container {
            padding: 5vw;
            background: #fafcfc;
          }
          div {
            padding-top: 3px;
          }
        }
      }
      &-bottom {
        background: #c7a981;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        flex: 1;
      }
    }
  }
}
