.login_form {
  padding: 30px 40px;
  width: 300px;
  background: #fff;
  h3 {
    text-align: center;
    color: rgb(48, 128, 254);
    letter-spacing: 12px;
    margin-bottom: 20px;
  }
  .login_button {
    width: 100%;
  }
}
